import React from "react";

const MarqueeText = () => {
    return(
        <div className="marquee-container">
            <div className="marquee">
                <p>CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;</p>
            </div>
            <div className="marquee marquee2">
                <p>CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;CANGSTER&nbsp;</p>
            </div>
        </div>
    );
}

export default MarqueeText;