// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { connect } from "../redux/blockchain/blockchainActions";
// import { fetchData } from "../redux/data/dataActions";
// import Web3 from "web3";
// import "rsuite/styles/index.less";
// import "rsuite/dist/rsuite.min.css";
// import { Notification, toaster } from "rsuite";
// import { Loader } from "rsuite";

// import { Provider } from "react-redux";
// import store from "../redux/store";

const ButtonWhite = ({ value, mobileMenuOpen2, setMobileMenuOpen2, whiteListed, windowSize }) => {

    // const dispatch = useDispatch();
    // const blockchain = useSelector((state) => state.blockchain);
    // const data = useSelector((state) => state.data);
    // const [walletAddress, setAddress] = useState("Not Connected");
    // const [claimingNft, setClaimingNft] = useState(false);
    // const [feedback, setFeedback] = useState(``);
    // const [tokens, settokens] = useState(1);
    // const [brd, setbrd] = useState("2px solid #F0F0F0F");
    // const [bxsh, setbxsh] = useState("0px 0px 3px 0px #F0F0F0F");
    // const [DOT, setDOT] = useState("red");

    // const [whitlisted, setWhitelisted] = useState(false);
    // const [isMinted, setMinted] = useState(false);

    // const [type, setType] = React.useState("info");
    // const [placement, setPlacement] = React.useState("topStart");
    // const errmessage = (
    //     <Notification type={"error"} header={"error"} closable>
    //         Sorry, something went wrong please try again later.
    //     </Notification>
    // );
    // const txmessage = (
    //     <Notification type={"success"} header={"success"} closable>
    //         Congrats, Mint Was successfull.
    //     </Notification>
    // );
    // const mntmessage = (
    //     <Notification type={"info"} header={"success"} closable>
    //         <Loader /> Minting in Progress....
    //     </Notification>
    // );
    // const [CONFIG, SET_CONFIG] = useState({
    //     CONTRACT_ADDRESS: "",
    //     SCAN_LINK: "",
    //     NETWORK: {
    //         NAME: "",
    //         SYMBOL: "",
    //         ID: 0,
    //     },
    //     NFT_NAME: "",
    //     SYMBOL: "",
    //     MAX_SUPPLY: 1,
    //     DISPLAY_COST: 0,
    //     WL_Display: 0,
    //     GAS_LIMIT: 0,
    //     MAX_PER_TX: 0,
    //     MARKETPLACE: "",
    //     MARKETPLACE_LINK: "",
    //     Telegram: "",
    //     Discord: "",
    //     Twitter: "",
    //     SHOW_BACKGROUND: true,
    // });

    // const claimNFTs = () => {
    //     let cost = CONFIG.DISPLAY_COST * tokens;
    //     let price = Web3.utils.toWei(cost.toString(), "ether");
    //     let gasLimit = CONFIG.GAS_LIMIT;
    //     let totalGasLimit = String(gasLimit);
    //     console.log("Cost: ", price);
    //     console.log("Gas limit: ", totalGasLimit);
    //     setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    //     setClaimingNft(true);
    //     setbrd("2px solid #F0F0F0F");
    //     setbxsh("0px 0px 3px 0px #F0F0F0F");
    //     toaster.push(mntmessage, { placement });
    //     blockchain.smartContract.methods
    //         .mintForCangsterWL(tokens)
    //         // .mintForCangster(tokens)
    //         .send({
    //             gasLimit: String(totalGasLimit),
    //             to: CONFIG.CONTRACT_ADDRESS,
    //             from: blockchain.account,
    //             value: price,
    //         })
    //         .once("error", (err) => {
    //             console.log(err);
    //             setFeedback("Sorry, something went wrong please try again later.");
    //             setClaimingNft(false);
    //             toaster.push(errmessage, { placement });
    //             setbrd("2px solid #F0F0F0F");
    //             setbxsh("0px 0px 0px 0px #F0F0F0F");
    //         })
    //         .then((receipt) => {
    //             console.log(receipt);
    //             setFeedback(
    //                 `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
    //             );
    //             toaster.push(txmessage, { placement });
    //             setbrd("2px solid #F0F0F0F");
    //             setbxsh("0px 0px 0px 0px #F0F0F0F");
    //             setClaimingNft(false);
    //             dispatch(fetchData(blockchain.account));
    //         });
    // };

    // const decrementtokens = () => {
    //     let newtokens = tokens - 1;
    //     if (newtokens < 1) {
    //         newtokens = 1;
    //     }
    //     settokens(newtokens);
    // };

    // const incrementtokens = () => {
    //     let newtokens = tokens + 1;
    //     if (newtokens > CONFIG.MAX_PER_TX) {
    //         newtokens = CONFIG.MAX_PER_TX;
    //     }
    //     settokens(newtokens);
    // };

    // const whitelistForCangster = async () => {
    //     const ret = await blockchain.smartContract.methods
    //         .whitelistForCangster(blockchain.account)
    //         .call();
    //     console.log(`whitelistForCangster : ${ret}`);
    //     setWhitelisted(ret);
    // };

    // const mintedForCangster = async () => {
    //     const ret = await blockchain.smartContract.methods
    //         .mintedForCangster(blockchain.account)
    //         .call();
    //     console.log(`mintedForCangster : ${ret}`);
    //     setMinted(ret);
    // };

    // const getData = () => {
    //     if (blockchain.account !== "" && blockchain.smartContract !== null) {
    //         dispatch(fetchData(blockchain.account));
    //         setAddress(
    //             blockchain.account.substring(0, 5) +
    //             " . . . " +
    //             blockchain.account.substring(38, 42)
    //         );
    //         setDOT("green");
    //     }
    // };

    // const getConfig = async () => {
    //     const configResponse = await fetch("/config/config.json", {
    //         headers: {
    //             "Content-Type": "application/json",
    //             Accept: "application/json",
    //         },
    //     });
    //     const config = await configResponse.json();
    //     SET_CONFIG(config);
    // };

    // useEffect(() => {
    //     getConfig();
    // }, []);

    // useEffect(() => {
    //     getData();
    //     whitelistForCangster();
    //     mintedForCangster();
    // }, [blockchain.account]);




    const handleCheckMobile = () => {
        if (windowSize < 1501) {
            setMobileMenuOpen2(!mobileMenuOpen2);
            whiteListed();
        } else {
            whiteListed();
            // return false;
        }
    }

    return (

        <>
            {/* {blockchain.smartContract ? (
                <div className="button_white" onClick={() => {
                    handleCheckMobile();
                }}>
                    <p>Check Whitelist</p>
                </div>

            ) : */}

                <div className="button_white"
                    onClick={() => {
                        window.open('https://opensea.io/collection/cangster-genesis')}}
                >
                    <p>OPENSEA</p>
                </div>

            {/* } */}
        </>
    );
}

export default ButtonWhite;