import React, { useEffect, useState } from "react";
import { debounce } from 'lodash';
import { Routes, Route, useLocation } from 'react-router-dom';

import FirstPage from "./pages/FirstPage";
import MainPage from "./pages/MainPage";
import NewsPage from "./pages/NewsPage";

import Header from "./component/Header";
import Footer from "./component/Footer";
import ModeButton from "./component/ModeButton";

import "./css/main.css";

const App = () => {

  // Now url
  const location = useLocation();

  // Header menu
  const [title, setTitle] = useState([
    {id:1, title: "HOME", link:"/main"},
    {id:2, title: "NEWS", link:"/news"},
    {id:3, title: "MINT", link:"/mint"},
    // {id:4, title: "CONNECT WALLET"},
  ]);

  // Now page
  const [status, setStatus] = useState("HOME");

  // Light/Dark mode
  const [isLight, setIsLight] = useState(false);

  // modeButtonBottom
  const [modeBottom, setModeBottom] = useState(false);

  // window width 사이즈
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  // 무한 로딩 방지하고 1초에 한번씩 사이즈 측정
  const handleResize = debounce(() => {
      setWindowSize(window.innerWidth);
  }, 1000);

  // popup시 뒷 배경 스크롤 방지
  const handleScroll = (value) => {
    if (value === true) {
        document.body.style.overflow = "hidden";
    } else if (value === false) {
        document.body.style.overflow = "unset";
    } else {
        return false;
    }
  }

  // mode button 스크롤시 위치
  const handleModeButton = () => {
    if (window.scrollY === (document.documentElement.scrollHeight - document.documentElement.clientHeight)) {
      setModeBottom(true);
      return;
    } else {
      setModeBottom(false);
      return;
    }
  };

  // mode button 스크롤시 위치
  useEffect(() => {
    window.addEventListener('scroll', handleModeButton);
    return () => {
      window.removeEventListener('scroll', handleModeButton); //clean up
    };
  }, []);

  // window width 사이즈 변경 감지
  useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      }
  }, []);

  return (
    <>
      <div 
        className={
          (location.pathname === "/" && isLight === true) ? "background light first" : 
          (location.pathname === "/" && isLight === false) ? "background first" : 
          (location.pathname === "/main" && isLight === true) ? "background light" : 
          (location.pathname === "/main" && isLight === false) ? "background" : 
          (location.pathname === "/news" && isLight === true) ? "background light otherpage" : 
          (location.pathname === "/news" && isLight === false) ? "background otherpage" : "background"
        }
      >
        {/* mode button */}
        <ModeButton isLight={isLight} setIsLight={setIsLight} modeBottom={modeBottom} />
        {/* header */}
        <Header
          title={title}
          setStatus={setStatus}
          isLight={isLight}
          location={location.pathname}
          handleScroll={handleScroll}
          windowSize={windowSize}
        />
        {/* //header */}
        {/* container */}
        <Routes>
          <Route path="/" element={<FirstPage />} />
          <Route path="/main" element={<MainPage windowSize={windowSize} isLight={isLight} handleScroll={handleScroll}/>} />
          <Route path="/news" element={<NewsPage windowSize={windowSize} isLight={isLight} handleScroll={handleScroll}/>} />  
        </Routes>
        {/* //container */}
        {/* footer */}
        <Footer />
        {/* //footer */}
      </div>
    </>
  );
}

export default App;