import React from "react";

const ModalAlert = ({modalVisible, setModalVisible, confirm, content}) => {
    return(
        <div className={modalVisible === true ? "modal_background open" : "modal_background"}
            onClick={() => {
                setModalVisible(!modalVisible);
            }}
        >
            <div className="modal_contents alert">
                <p>{content}</p>
                <div className="modal_close_button"
                    onClick={() => {
                        setModalVisible(!modalVisible);
                    }}
                >{confirm}</div>
            </div>
        </div>
    );
}

export default ModalAlert;