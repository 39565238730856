import React, { useEffect, useState } from "react";
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';

import ButtonBlackWL from "./ButtonBlackWL";
import ButtonWhiteWL from "./ButtonWhiteWL";

import ModalAlert from "./ModalAlert";
import ModalAlert2 from "./ModalAlert2";

const TitleList = ({ title, setStatus, mobileMenuOpen, setMobileMenuOpen, comingSoon, location, windowSize }) => {

    const navMap = title.map((data) => (
        <li
        className={location === data.link ? "on" : ""}
        key={data.id}
        >
            <Link
                onClick={() => {
                    if(data.title === "MINT") {
                        comingSoon();
                        setStatus("HOME");
                    } else {
                        setStatus(data.title);
                        setMobileMenuOpen(!mobileMenuOpen);
                    }
                }}
                to={data.link === "/mint" ? "/main" : data.link}
            >
                {data.title}
            </Link>
        </li>
    ));

    return(
    <nav 
        className={(windowSize < 1501) & mobileMenuOpen ? "mobile open" : "mobile"}
        >
        <div
        onClick={() => {
          setMobileMenuOpen(!mobileMenuOpen);  
        }} 
        className="mobile_button" />
        <ul className="navigation">
            {navMap}
        </ul>
    </nav>
    );

};

const HeaderNav = ({title, setStatus, isLight, location, handleScroll, windowSize, acount}) => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);


    const [mobileMenuOpen2, setMobileMenuOpen2] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);

    // comingsoon modal
    const comingSoon = () => {
        setModalVisible(!modalVisible);
    }
     // WL modal
     const whiteListed = () => {
        setModalVisible2(!modalVisible2);
    }

    useEffect(()=>{
        handleScroll(mobileMenuOpen);
    }, [mobileMenuOpen]);

    useEffect(()=>{
        handleScroll(modalVisible);
    }, [modalVisible]);


    useEffect(()=>{
        handleScroll(mobileMenuOpen2);
    }, [mobileMenuOpen2]);

    useEffect(()=>{
        handleScroll(modalVisible2);
    }, [modalVisible2]);

    return(
        <div 
            className={windowSize < 1501 ? "gnb_box mobile" : "gnb_box"}
        >
            <h1><a href="/">CANGSTER</a></h1>
            <div className="gnb_box_inner">
                <TitleList title={title} setStatus={setStatus} mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} comingSoon={comingSoon} location={location} windowSize={windowSize}/>
                {isLight === false ? 
                <ButtonBlackWL 
                    value="OPENSEA"
                    // mobileMenuOpen2={mobileMenuOpen2}
                    // setMobileMenuOpen2={setMobileMenuOpen2}
                    // whiteListed={whiteListed}
                    windowSize={windowSize}
                /> 
                : 
                <ButtonWhiteWL
                    value="OPENSEA"
                    // mobileMenuOpen2={mobileMenuOpen2}
                    // setMobileMenuOpen2={setMobileMenuOpen2}
                    // whiteListed={whiteListed}
                    windowSize={windowSize}
                />
                }
            </div>
            <ModalAlert 
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                confirm="OK"
                content="SOLD OUT!"
            />
            <ModalAlert2 
                modalVisible2={modalVisible2}
                setModalVisible2={setModalVisible2}
                confirm="OK"
                content="Address"
            />
        </div>
    );

}

export default HeaderNav;