import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchData } from "../redux/data/dataActions";

const ModalAlert2 = ({ modalVisible2, setModalVisible2, confirm, content }) => {

    // const dispatch = useDispatch();
    // const blockchain = useSelector((state) => state.blockchain);
    // const [walletAddress, setAddress] = useState("Not Connected");

    // const [whitlisted, setWhitelisted] = useState(false);

    // const whitelistForCangster = async () => {
    //     const ret = await blockchain.smartContract.methods
    //         .whitelistForCangster(blockchain.account)
    //         .call();
    //     console.log(`whitelistForCangster : ${ret}`);
    //     setWhitelisted(ret);
    // };

    // const getData = () => {
    //     if (blockchain.account !== "" && blockchain.smartContract !== null) {
    //         dispatch(fetchData(blockchain.account));
    //         setAddress(
    //             blockchain.account.substring(0, 5) +
    //             " . . . " +
    //             blockchain.account.substring(38, 42)
    //         );
    //     }
    // };

    // useEffect(() => {
    //     getData();
    //     whitelistForCangster();
    // }, [blockchain.account]);


    return (
        <div className={modalVisible2 === true ? "modal_background open" : "modal_background"}
            onClick={() => {
                setModalVisible2(!modalVisible2);
            }}
        >
            <div className="modal_contents alert">
                    <>
                    {/* <p>{walletAddress}</p> */}
                        {/* <p>{blockchain.account.substring(4, 0)} ... {blockchain.account.substring(blockchain.account.length - 4)}</p> */}
                        <br />
                        {/* {whitlisted === true ? (<p>IS WHITELISTED INDEED!</p>) : <p>Is Not Whitelisted.</p>} */}
                        {/* <br /> */}
                        {/* <p>{config.address.substring(4, 0)} ... {config.address.substring(config.address.length - 4)}</p> */}
                    </>
                <div className="modal_close_button"
                    onClick={() => {
                        setModalVisible2(!modalVisible2);
                    }}
                >{confirm}</div>
            </div>
        </div>
    );
}
export default ModalAlert2;