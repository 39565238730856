import React from "react";

const ModalNews = ({modalVisible, setModalVisible, data}) => {

    return(
        <div className={modalVisible === true ? "modal_background news open" : "modal_background news"}
            onClick={() => {
                setModalVisible(!modalVisible);
            }}
        >
            <div className="modal_contents">
                <div className="modal_close_button"
                    onClick={() => {
                        setModalVisible(!modalVisible);
                    }}
                >CLOSE</div>
                <div className="top_box">
                    {/* <div className="top_box_img"> */}
                        {/* <img src={data.img} alt="news_image" /> */}
                    {/* </div> */}
                </div>
                <div className="bottom_box">
                    <p>{data.date}</p>
                    <p>{data.title}</p>
                    <a style={{ color: "black"}} href={data.url} target="_blank">{data.urlName}<br/><br/></a>
                    <p>{data.contents}</p>
                </div>
            </div>
        </div>
    );
}

export default ModalNews;