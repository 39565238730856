import React from "react";

const ButtonSWhite = ({ value, comingSoon }) => {
    return (
        <div className="button_s_white"
            onClick={() => {
                comingSoon();
            }}
        >
            <p>{value}</p>
        </div>
    );
}

export default ButtonSWhite;