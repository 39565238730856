import React, {useState, useEffect} from "react";
import MarqueeText from "../component/MarqueeText";
import ButtonSBlack from "../component/ButtonSBlack";
import ButtonSWhite from "../component/ButtonSWhite";

import ModalAlert from "../component/ModalAlert";
import img1 from "../assets/img/1.png";
import img2 from "../assets/img/2.png";
// import img3 from "../assets/img/3.png";


// Canimal Icon
const CanimalIcon = props => {
    return(
        <div className="canimal_icon_box">
            { props.children }
        </div>
    );
}

// eslint-disable-next-line no-unused-vars
const GradientDiv = ({title}) => {
    return(
        <div className="gradient_div">
            <p>{title}</p>
        </div>
    );
}

const MainPage = ({windowSize, isLight, handleScroll}) => {

    const [modalVisible, setModalVisible] = useState(false);

    const comingSoon = () => {
        setModalVisible(!modalVisible);
    }

    useEffect(()=>{
        handleScroll(modalVisible);
    }, [handleScroll, modalVisible]);
    
    return (
        <div className="container">
            {windowSize < 1501 ?  <MarqueeText /> : null}
            {/* content1 */}
            <div className="content1">
                <div className="content1_inner">
                    <h2><span>CAN</span> DO<br /> ANYTHING!</h2>
                    <p>CANGSTERS NFT PROJECT</p>
                    {isLight === false ? 
                    <ButtonSBlack
                        // comingSoon={comingSoon}
                        value="SOLD OUT!"
                    /> : 
                    <ButtonSWhite
                        // comingSoon={comingSoon}
                        value="SOLD OUT!"
                    /> }
                </div>
                <div className="main_cangster_box">
                    <div className="main_cangster1"></div>
                    <div className="main_cangster2"></div>
                    <div className="main_cangster3"></div>
                    <div className="main_cangster4"></div>
                </div>
            </div>
            {/* content2 */}
            <div className="content2">
                <h3><span>WE</span> ARE<br /> ADVENTURERS!</h3>
                <p>We are Cangsters the adventurers! Grab your gear and explore Caniverse, the world full of fun and surprises, a dope artistic place that can only be seen through the magical vending machine. Join the coolest gang on the block! Together, we CAN do anything!</p>
            </div>
            {/* content3 */}
            <div className="content3">
            <CanimalIcon>
                    <div className="canimal_icon canimal_icon1"></div>
                    <div className="canimal_icon canimal_icon2"></div>
                    <div className="canimal_icon canimal_icon3"></div>
                    <div className="canimal_icon canimal_icon4"></div>
                    <div className="canimal_icon canimal_icon5"></div>
                    <div className="canimal_icon canimal_icon6"></div>
                    <div className="canimal_icon canimal_icon7"></div>
                    <div className="canimal_icon canimal_icon8"></div>
                    <div className="canimal_icon canimal_icon9"></div>
                    <div className="canimal_icon canimal_icon10"></div>
                    <div className="canimal_icon canimal_icon11"></div>
                    <div className="canimal_icon canimal_icon12"></div>
                    <div className="canimal_icon canimal_icon13"></div>
                    <div className="canimal_icon canimal_icon14"></div>
                    <div className="canimal_icon canimal_icon15"></div>
                    <div className="canimal_icon canimal_icon16"></div>
                    <div className="canimal_icon canimal_icon17"></div>
                </CanimalIcon>
                <CanimalIcon>
                    <div className="canimal_icon canimal_icon34"></div>
                    <div className="canimal_icon canimal_icon35"></div>
                    <div className="canimal_icon canimal_icon36"></div>
                    <div className="canimal_icon canimal_icon37"></div>
                    <div className="canimal_icon canimal_icon38"></div>
                    <div className="canimal_icon canimal_icon39"></div>
                    <div className="canimal_icon canimal_icon40"></div>
                    <div className="canimal_icon canimal_icon41"></div>
                    <div className="canimal_icon canimal_icon42"></div>
                    <div className="canimal_icon canimal_icon1"></div>
                    <div className="canimal_icon canimal_icon2"></div>
                    <div className="canimal_icon canimal_icon3"></div>
                    <div className="canimal_icon canimal_icon4"></div>
                    <div className="canimal_icon canimal_icon5"></div>
                    <div className="canimal_icon canimal_icon6"></div>
                    <div className="canimal_icon canimal_icon7"></div>
                    <div className="canimal_icon canimal_icon8"></div>
                </CanimalIcon>
            </div>
            {/* content3 text */}
            <p>Cangster is an exclusive collection of 10,000 real-life experience-enabled PFPs on the Ethereum blockchain, created by the Canne-winning artist Calvin with 619 unique properties, also known as the father of Pucca.</p>
            
            
            {/* content5 */}
            <div className="content5">
                <div className="content5_img"></div>
                <div className="content5_inner">
                    <h3>
                    cangster <br/>Project
                        <span> Vision</span>
                    </h3>
                    <p className="content5 p1">Empowerment of WEB3 via IRL<br/>Experience-enabled NFTs</p><br/>
                    <p>Starting with the PFP NFTs, Cangster envisions creating an open ecosystem where WEB3 entrepreneurs and creators can connect, share, enjoy and rest together via IRL-based infrastructure.
                    </p>
                    {/* <div className="gradient_div_box">
                        <GradientDiv title="NFT NYC" />
                        <GradientDiv title="NFT LA" />
                        <GradientDiv title="NFT BZL" />
                        <GradientDiv title="NFT SEOUL" />
                        <GradientDiv title="COACHELLA" />
                        <GradientDiv title="EDC" />
                        <GradientDiv title="UMF" />
                        <GradientDiv title="CONSENSUS" />
                    </div> */}
                </div>
            </div>

            
            {/* content4 */}
            <div className="content4 clear_float">
                <h3>
                <span>Caniverse</span><br/>the Hub of<br/> WEB3
                </h3>
                <p>We love WEB3 people.</p>
                <p>We want to deliver experiential value by building NFT membership-based physical hubs of WEB3, 
                    called Caniverse, in the world’s representative cities. Caniverse is for the WEB3 
                    entrepreneurs and creators where they can connect, enjoy, play and rest. 
                    Together with the community, we will decide on where to build, amenities, the vibe and all.</p>
                <div className="caniverse_img_box">
                    <div className="caniverse_img1"></div>
                    <div className="caniverse_img2"></div>
                    <div className="caniverse_img3"></div>
                </div>
            </div>


            {/* content6 */}
            <div className="content6">
                {/* <div className="content6_img"></div> */}
                <div className="content6_sec1">
                    {/* <img className="C6" src={img1} alt="png"/> */}
                    <img className="C6" src={img2} alt="png"/>
                </div>
                <h3 className="web">
                        Leave your NFT’s<br/> mark IRL on the <span>Animation</span>
                    </h3>
                    <h3 className="mobile">
                        Leave your NFT’s <br/>
                        mark IRL on the<br/>
                        <span>Animation</span>
                    </h3>
                <p className="content6_content web2">We love stories. </p> <br/>
                <p className="content6_content web1">
                We want our stories to be forever remembered. So put your spirit into the Cangster NFTs, and let’s build the community together. Canimals animation season 3 is under planning, and your NFTs can be featured and aired via globally major platforms. Leave your NFT’s everlasting mark IRL on the animation. Our work will be forever recorded and told to generations.
                </p>
                <p className="content6_content mobile">
                We love stories. <br/><br/>
                We want our stories<br/>
                to be forever remembered.<br/>
                So put your spirit<br/>
                into the Cangster NFTs,<br/>
                and let’s build the community together.<br/>
                Canimals animation season<br/>
                3 is under planning, and your NFTs can be featured<br/>
                and aired via globally<br/>
                major platforms.<br/>
                Leave your NFT’s<br/>
                everlasting mark<br/>
                IRL on the animation.<br/>
                Our work will be forever<br/>
                recorded and told to generations.
                </p>
            </div>
            {/* content7 */}
            <div className="content7">
                {/* <div className="content7_img"></div> */}
                <div className="content7_sec1">
                    <img className="C7" src={img1} alt="png"/>
                    {/* <img className="C7" src={img3} alt="png"/> */}
                    {/* <img className="C6" src={img2} alt="png"/> */}
                </div>
                <h3 className="web">
                    Roadmap Year <span>1</span>
                    </h3>
                    <h3 className="mobile">
                        Roadmap Year<br/>
                        <span>1</span>
                    </h3>
                {/* <p className="content7_content web2">We love stories. </p> <br/> */}
                <p className="content7_content web1">
                For the first year, we plan to focus on voting on the direction of Caniverse, quality partnerships through Cangsterization, and finally fundraising. If you are one of the original Cangsters, you will automatically gain admission to our ecosystem and get brand collaboration drops, events access, and be the frontrunner in building our foundation.
                </p>
                <p className="content7_content mobile">
                For the first year,<br/>
                we plan to focus on voting on the<br/>
                direction of Caniverse, quality partnerships<br/>
                through Cangsterization, and finally fundraising.<br/>
                If you are one of the original Cangsters,<br/>
                you will automatically gain admission<br/>
                to our ecosystem and get brand collaboration drops,<br/>
                events access, and be the<br/>
                frontrunner in building our foundation.
                </p>
            </div>
            <ModalAlert
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                confirm="OK"
                content="SOLD OUT!"
            />
        </div>
    );
}

export default MainPage;