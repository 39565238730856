import React from "react";
import MarqueeText from "./MarqueeText";
import HeaderNav from "./HeaderNav";

const Header = ({ title, setStatus, isLight, location, handleScroll, windowSize }) => {
    
    console.log(windowSize);
    
    return(
        <header>
            <HeaderNav
                title={title}
                setStatus={setStatus}
                isLight={isLight}
                location={location}
                handleScroll={handleScroll}
                windowSize={windowSize}
            />
            {windowSize < 1501
                ?   null
                :   <div className="marquee_container_box">
                        <MarqueeText />
                    </div>
            }
        </header>
    );
}

export default Header;