import React, { useState } from "react";

import ModalAlert from "./ModalAlert";

const Footer = () => {

    const [modalVisible, setModalVisible] = useState(false);

    return (
        <footer>
            <div className="footer_box">
                <h2><a href="/">logo</a></h2>
                <ul>
                    <li onClick={() => {
                        window.open('https://opensea.io/collection/cangster-genesis')}}
                    >Rarity Sniper</li>
                    <li onClick={() => {
                        window.open('https://raritysniper.com/nft-drops-calendar')}}
                    >Opensea</li>
                    <li onClick={() => {
                        window.open('https://discord.gg/QmtGZvSbFw')
                    }}>Discord</li>
                    <li onClick={() => [
                        window.open('https://twitter.com/CangstersNFT')
                    ]}>Tweeter</li>
                </ul>
                <p className="clear_float">© 2022 Cangster. All rights reserved.</p>
            </div>
            <ModalAlert
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                confirm="OK"
                content="INVITE ONLY"
            />
        </footer>
    );
}

export default Footer;