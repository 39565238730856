import React from "react";

const ModeButton = ({isLight, setIsLight, modeBottom}) => {
    return(
        <div 
          onClick={()=> {
            setIsLight(!isLight);
          }}
          // className={isLight === false ? "mode_button dark" : "mode_button light"}
        
        className={
            (isLight === true && modeBottom === true) ? "mode_button light bottom" :
            (isLight === true && modeBottom === false) ? "mode_button light" : 
            (isLight === false && modeBottom === true) ? "mode_button dark bottom" : "mode_button dark"
          }
        >
          <p>
              {isLight === false ? "DARK" : "LIGHT"}
              <br />
              <span>MODE</span>
          </p>
          <div className="mode_button_img"></div>
        </div>
    );
}

export default ModeButton;