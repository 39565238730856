import React from "react";
import { Link } from 'react-router-dom';
import ButtonBlack from "../component/ButtonBlack";

const FirstPage = () => {
    return(
        <div className="background firstpage">
            <Link to="/main">
                <ButtonBlack value="CAN WE BE FRENZ?" />
            </Link>

            <div className="firstpage_canimal"></div>
        </div>
    );
}

export default FirstPage;