import React from "react";

const ButtonBlack = ({ value, mobileMenuOpen, setMobileMenuOpen, comingSoon, windowSize }) => {
    
    const handleCheckMobile = () => {
        if (windowSize < 1501) {
            setMobileMenuOpen(!mobileMenuOpen);
            comingSoon();
        } else  {
            comingSoon();
            // return false;
        }
    }

    return(
        <div className="button_black"
        onClick={() => {
            handleCheckMobile();
        }}>
            <p>{value}</p>
        </div>
    );
}

export default ButtonBlack;
